import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, NavItem, Button, NavLink as BSNavLink } from 'reactstrap';
import PulseLoader from 'react-spinners/PulseLoader';
import bn from '../../../lib/utils/bemnames';
import { navItems } from '../../../routes/menu';
import R from '../../../lib/constants/R';
import SidebarPage from './SidebarPages';
import SourceLink from '../SourceLink';
import PropTypes from '../../../lib/utils/propTypes';
import { MdMenuOpen, MdRefresh } from 'react-icons/md';
import { genericsActions } from '../../../store/actions';
import api from '../../../api/appApi/api';

const sidebarBackground = {
	// backgroundImage: `url("${R.images.sidebar}")`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpenSistema: false,
			isOpenSide: true,
			isUpdating: 0,
		};
	}

	handleSidebarControlButton = event => {
		event.preventDefault();
		event.stopPropagation();
		document
			.querySelector('.cr-sidebar')
			.classList.toggle('cr-sidebar--open');
		document.querySelector('.cr-header').classList.toggle('open');

		if (
			document
				.querySelector('.cr-sidebar')
				.classList.contains('cr-sidebar--open')
		) {
			this.state.isOpenSide = false;
		} else {
			this.state.isOpenSide = true;
		}

		const { onSidebar } = this.props;
		onSidebar(this.state.isOpenSide);
	};

	handleClick = name => () => {
		this.setState(prevState => {
			const isOpen = prevState[`isOpen${name}`];

			return {
				[`isOpen${name}`]: !isOpen,
			};
		});
	};

	modulePermission = modules => {
		return modules.map((item, index) => {
			console.log('item', item);
			return (
				<SidebarPage
					key={item.route.name + index}
					isOpen={this.state[`isOpen${item.route.name}`]}
					namePages={item.route.name}
					click={name => this.handleClick(name)}
					pageContents={item.submodules}
					IconPage={item.route.icon}
				/>
			);
		});
	};

	UpdateTableData = async () => {
		const { isUpdating } = this.state;
	  
		if (isUpdating !== 0) {
		  return;
		}
	  
		this.setState({ isUpdating: 1 });
	  
		try {
		  const response = await api.post('/executar-python');
	  
		  switch (response.status) {
			case 200:
			  console.log('Script executado com sucesso');
			  this.setState({ isUpdating: 2 });
			  break;
			
			case 429:
			  console.log('Script já está sendo executado');
			  this.setState({ isUpdating: 3 });
			  break;
			
			default:
			  console.log(`Resposta inesperada: ${response.status}`);
			  this.setState({ isUpdating: 4 });
		  }
		} catch (error) {
		  console.error('Erro ao executar o script:', error);
		  this.handleScriptError();
		}
	  };
	  
	  handleScriptError = () => {
		this.setState({ isUpdating: 3 });
	  
		setTimeout(() => {
		  this.setState({ isUpdating: 0 });
		}, 10000); 
	  };
	  

	render() {
		const { modules, permissionsMenu, user } = this.props;
		return (
			<aside className={bem.b()} data-image={R.images.sidebar}>
				<div
					className={bem.e('background')}
					style={sidebarBackground}
				/>

				<div className={bem.e('content')}>
					<div style={{ display: 'flex', justifyContent: 'space-around', padding: '0 5px' }}>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<SourceLink style={{ backgroundColor: 'white', padding: 5, borderRadius: '0 0 10px 10px' }}>
								<img
									src={R.images.logo_oficial}
									height="40"
									alt=""
								/>
							</SourceLink>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Button
								outline
								onClick={this.handleSidebarControlButton}
								className={bem.e('menu')}>
								<MdMenuOpen size={20} />
							</Button>
						</div>
					</div>
					{modules.length > 0 ? (
						<div>
							<Nav vertical>
								{navItems.map(
									({ to, name, exact, Icon }, index) => (
										<NavItem
											key={name}
											className={bem.e('nav-item')}>
											<BSNavLink
												id={`navItem-${name}-${index}`}
												tag={NavLink}
												to={to}
												activeClassName="active"
												exact={exact}>
												<Icon
													className={bem.e(
														'nav-item-icon',
													)}
												/>
												<span className="nav-name">
													{name}
												</span>
											</BSNavLink>
										</NavItem>
									),
								)}
								{this.modulePermission(permissionsMenu)}

								<NavItem
									key='Atualização'
									>

									<Button
										onClick={this.UpdateTableData}
										className='updateButton'
										activeClassName="active"
									>
										<MdRefresh style={{ marginRight: 5 }} size={20} />
										<span className="nav-name">
											{this.state.isUpdating === 1 ? 'Atualizando...' : this.state.isUpdating === 3 ? 'Já em execução' : 'Atualizar'}
										</span>
									</Button>
								</NavItem>

							</Nav>
						</div>
					) : (
						<div align="center" className="mt-4">
							<PulseLoader color="#fff" />
						</div>
					)}
				</div>
				<div>

				</div>
			</aside>
		);
	}
}

const mapStateToProps = state => {
	return {
		modules: state.enterprise.modulesCompany,
		permissionsMenu: state.enterprise.permissionsMenu,
		user: state.auth.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSidebar: data => dispatch(genericsActions.sideBar(data)),
	};
};

Sidebar.propTypes = {
	permissionsMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
	modules: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

