import {
	MdBuild,
	MdDashboard,
	MdApps,
	MdAssignmentTurnedIn,
	MdBackupTable,
} from 'react-icons/md';
import {
	FaUserAlt,
	FaSuitcase,
	FaFileContract,
	FaMountain,
} from 'react-icons/fa';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { RiPriceTag3Fill } from 'react-icons/ri';

export const navItems = [
	{
		to: '/',
		name: 'Dashboard',
		exact: true,
		Icon: MdDashboard,
	},
];

export const navAux = [
	{
		to: '/tabela',
		name: 'Tabela',
		exact: false,
		IconSub: MdBackupTable,
		id: 242,
	},
	{
		to: '/aprovacao',
		name: 'Aprovação',
		exact: false,
		IconSub: MdAssignmentTurnedIn,
		id: 242,
	},
	{
		to: '/usuarios',
		name: 'Usuários',
		exact: false,
		IconSub: FaUserAlt,
		id: 242,
	},
];

export const routes = [
	{
		name: 'Auxiliares',
		icon: MdBuild,
		submodules: navAux,
	},
];
